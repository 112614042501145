<template>
  <label v-bind:for="component_id" v-if="component_meta.component.properties.show_label == 'Y'">
    {{ component_meta.label }}：
  </label>
  <span class="tip" v-if="component_meta.component.properties.show_label == 'Y'">{{ component_meta.tip }}</span>

  <div v-bind:id="component_id" class="hyperplasia" v-bind:class="{ 'hyperplasia-border': show_border }">
    <template v-for="(row, index) in component_value" v-bind:key="row.id">
      <div v-bind:id="row.id" class="row" v-bind:class="{ 'hyperplasia-item-border': show_item_border }">
        <div v-for="field_meta in component_meta.component.properties.fields" :key="field_meta.variable" :class="[
          'form-group',
          'col-' + field_meta.component.properties.col_width.mobile,
          'col-sm-' + field_meta.component.properties.col_width.pad,
          'col-md-' + field_meta.component.properties.col_width.pad,
          'col-lg-' + field_meta.component.properties.col_width.desktop
        ]">
          <div class="item drop">
            <span v-show="index < component_value.length - 1"><i class="fas fa-grip-vertical"></i></span>
          </div>
          <div class="item frame">
            <component v-bind:is="field_meta._component" v-bind:field="field_meta" v-bind:value="row[field_meta.variable]"
              @input="update_input_value(field_meta, $event, index)" v-bind:key="Math.random()"></component>
          </div>
          <div class="item buttons">
            <button v-show="index < component_value.length - 1" type="button" @click="delete_row(index)"
              class="btn btn-default btn-sm bg-red"><i class="fas fa-trash-alt"></i></button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  props: ['field', 'value', 'message'],
  emits: ['input'],
  data() {

    // 數值初始化
    let localValue = this.value;

    // 如果 localValue 不為陣列型態，則初始化為陣列
    if (typeof localValue !== 'object') {
      localValue = [];
    }

    localValue.push({});

    for (let i = 0; i < localValue.length; i++) {
      for (let key in this.field.component.properties.fields) {
        let variable = this.field.component.properties.fields[key].variable;
        if (localValue[i][variable] == undefined || localValue[i][variable] == null) {
          localValue[i][variable] = '';
        }
      }

      if (localValue[i]['id'] == undefined || localValue[i]['id'] == null) {
        localValue[i]['id'] = Math.random().toString().substring(2);
      }
    }


    let localMeta = this.field;
    if (typeof localMeta.component.properties.border !== 'string') {
      localMeta.component.properties.border = 'Y';
    }

    // 元件
    for (let key in localMeta.component.properties.fields) {
      localMeta.component.properties.fields[key]._component =
        this.$microlink.components[localMeta.component.properties.fields[key].component.type].component;
    }

    return {
      component_id: 'hyperplasia-' + Math.random().toString().substring(2),
      component_meta: localMeta,
      component_value: localValue,
      show_border: (localMeta.component.properties.border == 'Y'),
      show_item_border: (localMeta.component.properties.item_border == 'Y'),
    }
  },
  mounted() {
    let $this = this;

    $('#' + this.component_id).sortable({
      handle: ".item.drop",

      //cancel: '#' + this.component_id + " div.row:not([id])",

      update: function (event, ui) {


        // 被更換位置的項目，在 ui 裡有記錄，其被移動到哪個位置
        // 透過這個資訊，直接在 $this.component_value 將資料進行位置的更換
        // 以完成拖曳排序的功能
        let item_id = ui.item[0].id;
        let draggedItem = ui.item;
        let target = draggedItem.index();

        var sortableList = $(this);
        var lastItem = sortableList.find("div.row:last-child");

        if (ui.item.is(lastItem)) {
          // 阻止拖拽项被放置到最后一个位置
          ui.item.removeClass("ui-state-highlight"); // 移除高亮样式
          return false;
        }

        let source = -1;
        for (let key in $this.component_value) {
          if ($this.component_value[key].id == item_id) {
            source = key;
            break;
          }
        }

        let item = $this.component_value.splice(source, 1)[0];
        $this.component_value.splice(target, 0, item);
      }
    });
  },
  methods: {
    delete_row: function (index) {
      this.component_value.splice(index, 1);
      let $event = {};
      $event.temp = this.component_value;
      this.$emit('input', $event);
      this.$forceUpdate();
    },
    update_input_value: function (meta, $event, index) {
      if (this.component_value.length == index + 1) {
        this.component_value.push({});
      }
      this.component_value[index][meta.variable] = ($event.temp == undefined) ? $event.target.value : $event.temp;
      if (this.component_value[index]['id'] == undefined || this.component_value[index]['id'] == null) {
        this.component_value[index]['id'] = Math.random().toString().substring(2);
      }

      $event.temp = this.component_value;
      this.$emit('input', $event);
    }
  }
}
</script>

<style scoped>
span.tip {
  color: green;
}

div.hyperplasia-border {
  border: 1px solid #ced4da;
  padding: 20px;
  border-radius: 5px;
}

div.hyperplasia-border label:not(:first-child) {
  margin-top: 30px;
}

div.hyperplasia-item-border {
  border-bottom: 1px dotted #ced4da;
  padding: 10px;
}

div.item.drop {
  vertical-align: middle;
  display: inline-block;
  width: 20px;
}

div.item.frame {
  display: inline-block;
  width: 93%;
}

div.item.buttons {
  display: inline;
  margin-left: 10px;
}

.form-group {
  margin-bottom: 0;
}
</style>